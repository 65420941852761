import type { SearchClient } from 'algoliasearch';
import algoliasearch from 'algoliasearch/lite';
import { useMemo } from 'react';

const searchClient = algoliasearch(process.env.ALGOLIA_APP_ID!, process.env.ALGOLIA_API_KEY!);

export function getSearchClient() {
  return {
    ...searchClient,
    search(requests) {
      // if there is no text (empty query) then return an empty response
      if (requests.every(({ params }) => !params?.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
            hitsPerPage: 0,
            exhaustiveNbHits: false,
            query: '',
            params: ''
          }))
        });
      }

      return searchClient.search(requests);
    }
  } as SearchClient;
}

export function useSearchClient() {
  return useMemo(() => getSearchClient(), []);
}
