import { useMediaQuery } from 'react-responsive';
import config from '../../tailwind.config'; // Your tailwind config
import type { screens } from 'tailwindcss/defaultTheme';
import resolveConfig from 'tailwindcss/resolveConfig';

type Breakpoints = typeof screens;

const breakpoints = resolveConfig(config).theme?.screens as Breakpoints;

export function useBreakpoint<K extends keyof Breakpoints>(breakpointKey: K) {
  const bool = useMediaQuery({
    query: `(min-width: ${breakpoints[breakpointKey]})`
  });
  const capitalizedKey = breakpointKey[0].toUpperCase() + breakpointKey.substring(1);
  type Key = `is${Capitalize<K>}`;
  return {
    [`is${capitalizedKey}`]: bool
  } as Record<Key, boolean>;
}
