import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import Link from 'next/link';
import { Fragment, forwardRef } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import styles from './styles.module.scss';
import Image from 'next/image';
import { buildAssetUrl } from '~/lib/assetPathHelper';
import { useTranslation } from 'next-i18next';

type NavDropdownItemProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  href: string;
  isActive?: boolean;
  trackingId?: string; // headless ui overrides this prop so we have to pass it differently
};

const NavDropdownItem = forwardRef<HTMLAnchorElement, NavDropdownItemProps>((props, ref) => {
  const { trackingId, href, children, className, ...rest } = props;
  return (
    <Link
      id={trackingId && `nav-link-${trackingId}`}
      href={href}
      ref={ref}
      className={classNames(
        className,
        'rounded-md text-black hover:decoration-transparent py-4 px-2 flex flex-col',
        {
          'bg-brand-darkPurple/20': props.isActive
        }
      )}
      {...rest}
    >
      {children}
    </Link>
  );
});

type Props = {
  isTransparent?: boolean;
  title: string;
  items: Array<{
    id: string;
    title: string;
    subtitle?: string;
    href: string;
    newIcon?: boolean;
    classNames?: string;
  }>;
};

export function NavDropdown({ items, isTransparent, title }: Props) {
  const { t } = useTranslation();
  return (
    <Menu as="li" className={classNames(styles['nav-item'], 'font-inter')}>
      {({ open }) => (
        <>
          <Menu.Button
            as="a"
            role="button"
            className={classNames(
              styles['nav-link'],
              'flex items-center gap-x-1',
              isTransparent ? 'text-white' : 'text-black'
            )}
          >
            <>
              {title} {open ? <ChevronUp /> : <ChevronDown />}
            </>
          </Menu.Button>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
            as={Fragment}
          >
            <Menu.Items
              className="z-[9999] bg-white p-2 rounded-md flex flex-col absolute mt-2 border-t-[6px] shadow-md border-t-brand-darkPurple outline-none w-[300px] select-none"
              style={{ borderTopStyle: 'solid' }}
            >
              {items.map(({ id, title, subtitle, href, newIcon, classNames }) => {
                return (
                  <Menu.Item className={classNames} as={'div'} key={href}>
                    {({ active }) => (
                      <NavDropdownItem trackingId={id} href={href} isActive={active}>
                        <div className="flex flex-row">
                          <p className="font-bold text-lg text-brand-darkPurple mb-1">{title}</p>
                          {newIcon && (
                            <Image
                              src={buildAssetUrl(`/images/mp-planner/${t('common:mp-ads.new-icon')}.svg`)}
                              className="ml-1"
                              width="40"
                              height="20"
                              alt="New"
                            />
                          )}
                        </div>
                        {subtitle && <p className="text-sm mb-0">{subtitle}</p>}
                      </NavDropdownItem>
                    )}
                  </Menu.Item>
                );
              })}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}
