import create from 'zustand';

type GlobalSearchStore = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
};

export const useGlobalSearch = create<GlobalSearchStore>((set) => {
  return {
    isOpen: false,
    open: () => set({ isOpen: true }),
    close: () => set({ isOpen: false })
  };
});
