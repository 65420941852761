import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { Menu as MenuIcon, Search, ShoppingCart as CartIcon } from 'react-feather';
import trackEvent from '~/lib/analytics.service';
import Brand from '../brand';
import Link from 'next/link';
import PathMap from '~/shared/pathMap';
import styles from './styles.module.scss';
import { useTranslation } from 'next-i18next';
import { SideNav } from './SideNav';
import { NavDropdown } from './NavDropdown';
import { GlobalSearchOverlay } from '../global-search-overlay';
import { useGlobalSearch } from '../global-search-overlay/useGlobalSearch';
import { useCalendlyModal } from '~/lib/hooks/useCalendlyModal';
import { CalendlyModal } from '../calendly-modal';
import LanguageSwitcher from '../language-switcher';
import { useFeatureFlag } from '~/lib/hooks/abby';
import dynamic from 'next/dynamic';
import { useCreateCalendlyLink } from '~/shared/calendly';
import { buildAssetUrl } from '~/lib/assetPathHelper';
import Image from 'next/image';
import { useMachinePlannerUrl } from '~/lib/hooks/useMachinePlannerUrl';
import { ChinaQRCodeModal, useChinaQRCodeModal } from '../china-qrcode-modal';
import { useCountryCode } from '~/lib/hooks/useCountryCode';
import { Button } from '../button';

const Popup = dynamic(import('~/components/fair-banner-popup'), {
  ssr: false
});

const RBTXpertAbButton = dynamic(import('./rbtxpert-button'), {
  ssr: false
});

const NavLink = ({
  children,
  id,
  isTransparent,
  href,
  pathName,
  onClick
}: {
  children: React.ReactNode;
  id: string;
  isTransparent: boolean;
  pathName: string;
  href: string;
  onClick?: () => void;
}) => {
  const router = useRouter();

  const isActive = () => {
    return router.pathname.startsWith(pathName);
  };

  return (
    <li className={classNames(styles['nav-item'], 'font-inter')}>
      <Link
        id={`nav-link-${id}`}
        href={href}
        onClick={onClick}
        className={classNames(styles['nav-link'], isTransparent ? 'text-white' : 'text-black', {
          [styles.active]: isActive(),
          'font-bold': isActive()
        })}
      >
        {children}
      </Link>
    </li>
  );
};

type Props = {
  theme?: string;
  brandTheme?: string;
  hideBundle?: boolean;
  showBundleSpinner?: boolean;
  cartProductCount?: number;
  onBundleTabClick?: () => void;
  children?: React.ReactNode;
  isTransparent?: boolean;
  transparencyToggleTargetId?: string;
  hideOnMobile?: boolean;
  isFixed?: boolean;
};

const Header = ({
  onBundleTabClick,
  children,
  cartProductCount,
  theme = '',
  isTransparent = false,
  transparencyToggleTargetId: transparentToggleTargetId,
  hideOnMobile = false,
  isFixed
}: Props) => {
  const router = useRouter();
  const showCommunityDesignLink = useFeatureFlag('CommunityDesignLink');
  const machinePlannerUrl = useMachinePlannerUrl();
  const { isOpen, setIsOpen } = useCalendlyModal();
  const { isQRModalOpen, setIsQRModalOpen } = useChinaQRCodeModal();
  const { open: openGlobalSearch } = useGlobalSearch();
  const { t } = useTranslation();
  const [showMobileMenu, setShowMobileMenu] = useState<boolean | undefined>(undefined);
  const removeScrollBody = () => {
    const { body } = document;
    body.classList.remove('--disableScroll');
  };
  const countryCode = useCountryCode();
  const calendlyLink = useCreateCalendlyLink(router.locale);

  const [transparent, setTransparent] = useState(isTransparent);
  const [showShadow, setShowShadow] = useState(false);

  useEffect(() => {
    const checkScrollPosition = () => {
      setShowShadow(window.scrollY > 0);

      if (!transparentToggleTargetId) {
        return;
      }

      const headerSliderEl = document.getElementById(transparentToggleTargetId);
      if (!headerSliderEl) {
        return;
      }

      setTransparent(headerSliderEl.getBoundingClientRect().bottom > 0);
    };

    window.addEventListener('scroll', checkScrollPosition, { passive: true });

    return () => {
      window.removeEventListener('scroll', checkScrollPosition);
    };
  }, [transparentToggleTargetId]);

  useEffect(() => {
    return () => removeScrollBody();
  }, []);

  const hideMenuClick = () => {
    setShowMobileMenu(false);
    removeScrollBody();
  };

  const noScrollBody = () => {
    const { body } = document;
    body.classList.add('--disableScroll');
  };

  const showMenuClick = () => {
    setShowMobileMenu(true);
    noScrollBody();
  };

  const showBundleClick = () => {
    onBundleTabClick?.();
    trackEvent('MyRobotBundle', 'open');
  };

  const [localeBannerExists, setLocaleBannerExists] = useState(false);

  // NOTE: Adding [] as dependency seems to break it for some reason
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const el = document.getElementById('locale-banner');
    setLocaleBannerExists(!!el);
  });

  return (
    <>
      <div
        id="sidenav-overlay"
        onClick={hideMenuClick}
        role="presentation"
        className={classNames({
          [classNames(styles['show'], styles['animated'], styles['showIn'])]: showMobileMenu
        })}
      />
      <header
        className={classNames(
          styles['site-header'],
          styles[theme],
          'transition ease-in-out duration-300',
          'z-40 h-[80px] w-full',
          localeBannerExists ? 'top-[80px]' : 'top-0',
          isFixed ? 'fixed' : 'sticky',
          transparent
            ? showShadow
              ? 'bg-brand-darkPurple/60 backdrop-blur-md'
              : 'bg-transparent'
            : 'bg-white',
          {
            'hidden md:block': hideOnMobile,
            'shadow-md': showShadow
          }
        )}
      >
        <div className={classNames(styles['navi-slot'], 'flex mx-auto lg:px-16')}>
          <nav className="flex w-full">
            <button
              className={classNames(
                'navbar-toggler',
                styles['navbar-toggler'],
                'block xl:hidden pl-0',
                transparent ? 'text-white' : 'text-black'
              )}
              onClick={showMenuClick}
              type="button"
              aria-label="Menu"
            >
              <MenuIcon size={32} />
            </button>
            <div className="flex items-center flex-1">
              <Brand theme={transparent ? 'white' : 'dark'} className={classNames(styles.brand)} />
              <div className={classNames(styles['rbtx-navbar'], 'hidden xl:block')}>
                <ul className={classNames('flex items-center lg:ml-6 space-x-4')}>
                  <NavLink
                    id={'components'}
                    href={PathMap.components.as(['robots'])}
                    pathName={PathMap.components.pathname}
                    isTransparent={transparent}
                  >
                    {t('headerComponentsText')}
                  </NavLink>
                  <NavLink
                    id="solutions"
                    href={PathMap.solutions.as([''])}
                    pathName={PathMap.solutions.pathname}
                    isTransparent={transparent}
                  >
                    {t('headerUseCasesLinkText')}
                  </NavLink>
                  <div className="relative">
                    <div className="absolute w-8 left-[23%] -translate-y-1/2">
                      <Image
                        className={!machinePlannerUrl ? 'hidden' : ''}
                        width={100}
                        height={100}
                        alt="New"
                        src={buildAssetUrl(`/images/mp-planner/${t('common:mp-ads.new-icon')}_down.svg`)}
                      />
                    </div>
                    <NavDropdown
                      title={t('headerToolText')}
                      items={[
                        {
                          id: 'machine-planner',
                          title: t('headerCommunityLinkText'),
                          href: PathMap.machinePlanner.as(['']),
                          subtitle: t('headerCommunityLinkSubText'),
                          newIcon: !!machinePlannerUrl,
                          classNames: !machinePlannerUrl ? 'hidden' : undefined
                        },
                        {
                          id: 'gluing',
                          title: t('headerGluingLinkText'),
                          href: 'https://gluing.rbtx.com/',
                          subtitle: t('headerGluingLinkSubText')
                        },
                        {
                          id: '7th-axis',
                          title: t('header7thAxisLinkText'),
                          href: 'https://configurator.rbtx.com/7thaxis',
                          subtitle: t('header7thAxisLinkSubText')
                        },
                        {
                          id: 'rebel-cobot',
                          title: t('headerRebelCobotLinkText'),
                          href: 'https://tpdb2.truphysics.com/igus/Rebel/',
                          subtitle: t('headerRebelCobotLinkSubText')
                        },
                        {
                          id: 'gantry',
                          title: t('headerGantryLinkText'),
                          href: 'https://tpdb2.truphysics.com/igus/gantry/',
                          subtitle: t('headerGantryLinkSubText')
                        },
                        {
                          id: 'apiro',
                          title: t('headerApiroLinkText'),
                          href: 'https://igus.truphysics.com/Apiro/',
                          subtitle: t('headerApiroLinkSubText')
                        }
                      ]}
                      isTransparent={transparent}
                    />
                  </div>
                  {showCommunityDesignLink && (
                    <NavLink
                      id="rbtxperience"
                      href="https://tpdb2.truphysics.com/trubotics/RBTXperience-Desk/"
                      pathName="https://tpdb2.truphysics.com/trubotics/RBTXperience-Desk/"
                      isTransparent={transparent}
                      onClick={() => {
                        trackEvent('RBTXperience_topnavi', 'click');
                      }}
                    >
                      RBTXperience
                    </NavLink>
                  )}
                  <NavDropdown
                    title={t('headerServicesText')}
                    items={[
                      {
                        id: 'rbtxpert',
                        title: t('headerIntegratorLinkText'),
                        href: PathMap.rbtxpert.as([''])
                      },
                      {
                        id: 'cta',
                        title: t('headerCTAText'),
                        href: PathMap.customerTestingArea.as([''])
                      }
                    ]}
                    isTransparent={transparent}
                  />
                  <NavDropdown
                    title={t('headerHelpText')}
                    items={[
                      {
                        id: 'contact',
                        title: t('contactUsLinkText'),
                        subtitle: t('contactUsLinkSubText'),
                        href: PathMap.contact.as([''])
                      },
                      {
                        id: 'help',
                        title: t('headerFAQandArticleLinkText'),
                        subtitle: t('headerFAQandArticleLinkSubText'),
                        href: PathMap.help.as([''])
                      },
                      {
                        id: 'rbtx-method',
                        title: t('headerRBTXMethodLinkText'),
                        subtitle: t('headerRBTXMethodLinkSubText'),
                        href: PathMap.rbtxMethod.as([''])
                      }
                    ]}
                    isTransparent={transparent}
                  />
                </ul>
              </div>
              <div
                className={classNames(
                  'md:flex  p-3 flex-grow space-x-4 hidden rounded-full xl:ml-0 mx-4 truncate',
                  transparent ? 'bg-gray-100 opacity-50' : 'bg-gray-100'
                )}
                onClick={openGlobalSearch}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 opacity-30"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="gray"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
                <div
                  className={classNames(
                    'inline-block cursor-text truncate',
                    transparent ? ' text-gray-400' : 'bg-gray-100 text-gray-400'
                  )}
                >
                  {t('headerSearchPlaceholder')}
                </div>
              </div>
            </div>
            <div
              className={classNames(
                transparent ? 'text-white' : 'text-black',
                'space-x-2 md:space-x-6 flex items-center'
              )}
            >
              {countryCode == 'CN' ? (
                <Button
                  variant={transparent ? 'inverted' : 'default'}
                  onClick={() => setIsQRModalOpen(true)}
                  className={classNames('hidden md:block', {
                    'bg-white hover:bg-white/60 !text-brand-purple': transparent,
                    'border-none': !transparent
                  })}
                >
                  {t('headerBookCallText')}
                </Button>
              ) : (
                <RBTXpertAbButton
                  buttonVariant={transparent ? 'inverted' : 'default'}
                  href={calendlyLink}
                  classNames={classNames('hidden md:block', {
                    'bg-white hover:bg-white/60 !text-brand-purple': transparent,
                    'border-none': !transparent
                  })}
                />
              )}
              <div className="flex items-center justify-center border-none bg-transparent text-inherit lg:h-12">
                <LanguageSwitcher />
              </div>
              <button
                className="flex items-center md:hidden justify-center border-none bg-transparent text-inherit hover:bg-white/10 lg:w-12 lg:h-12 lg:rounded-full transition ease-in-out duration-300"
                onClick={openGlobalSearch}
                title={t('landing:uspCTA')}
              >
                <Search size={24} />
              </button>
              <button
                className="flex items-center justify-center border-none bg-transparent text-inherit hover:bg-white/10 lg:w-12 lg:h-12 lg:rounded-full transition ease-in-out duration-300 relative"
                onClick={showBundleClick}
                title={t('myRobotBundleToCartBtn')}
              >
                {cartProductCount ? (
                  <span
                    className={classNames(
                      'absolute right-0 top-0 w-4 h-4 rounded-full text-white bg-brand-orange text-xs font-bold flex items-center justify-center'
                    )}
                  >
                    {cartProductCount}
                  </span>
                ) : null}
                <CartIcon size={24} />
              </button>
            </div>
            <SideNav isOpen={showMobileMenu} onClose={hideMenuClick} />
          </nav>
        </div>
        {children}
        <ChinaQRCodeModal isOpen={isQRModalOpen} onClose={() => setIsQRModalOpen(false)} />
        <CalendlyModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
        <GlobalSearchOverlay />
      </header>
      <Popup />
    </>
  );
};

export default Header;
