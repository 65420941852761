import queryString from 'query-string';

/**
 * Returns a template function which replaces all {name}
 * occurences with the array values in the exact order. That function
 * accepts a second argument to append query parameters to the path.
 *
 * @param {string} asUrl - Base url
 * @returns {string} formatted path
 */
const as =
  (asUrl = '') =>
  (params: Array<string> = [], query: Record<string, string> = {}) => {
    let queryPath = '';
    if (query) {
      for (const [key, value] of Object.entries(query)) {
        if (value.toString() === 'false') {
          delete query[key];
        }
      }
      queryPath = Object.keys(query).length > 0 ? '?' : '';
      queryPath += queryString.stringify(query);
    }

    const path = params.reduce((prev, param) => prev.replace(/{[^{]*}/, param), `${asUrl}${queryPath}`);

    if (path.length > 1 && path.endsWith('/')) return path.slice(0, -1);
    return path;
  };

/**
 * Here are all pages listed. We use this list to manage a single source of links for client and server side.
 * Also the sitemap generation rely on that.
 *
 * generic - means that a part of the page url can vary
 */
const pathMap = {
  index: {
    pathname: '/',
    as: as('/')
  },
  partners: {
    pathname: '/partners',
    as: as('/partners')
  },
  help: {
    pathname: '/help',
    as: as('/help')
  },
  bundle: {
    pathname: '/bundle',
    as: as('/components/{categorySlug}/bundles/{slug}'),
    generic: true
  },
  wizard: {
    pathname: '/wizard',
    as: as('/integration/guide')
  },
  components: {
    pathname: '/components',
    as: as('/components/{category}'),
    generic: true
  },
  industry: {
    pathname: '/components',
    as: as('/components/{category}'),
    generic: true
  },
  contact: {
    pathname: '/contact',
    as: as('/contact')
  },
  prefabs: {
    pathname: '/complete-solutions',
    as: as('/complete-solutions/{slug}')
  },
  contactDone: {
    pathname: '/contact-done',
    as: as('/contact/done'),
    excludeInSitemap: true
  },
  dataPolicy: {
    pathname: '/data-policy',
    as: as('/data-policy')
  },
  faqPost: {
    pathname: '/faq-post',
    as: as('/faq/{slug}'),
    generic: true
  },
  helpArticle: {
    pathname: '/help-article',
    as: as('/help/articles/{slug}'),
    generic: true
  },
  integratorContact: {
    pathname: '/integration',
    as: as('/integration')
  },
  imprint: {
    pathname: '/imprint',
    as: as('/imprint')
  },
  partner: {
    pathname: '/partner',
    as: as('/partners/{slug}'),
    generic: true
  },
  wizardSuccess: {
    pathname: '/wizard-success',
    as: as('/integration/guide/success')
  },
  prefabProduct: {
    pathname: '/prefab',
    as: as('/old-solutions/{category}/{slug}'),
    generic: true
  },
  product: {
    pathname: '/product',
    as: as('/components/{category}/{slug}'),
    generic: true
  },
  productVariant: {
    pathname: '/product',
    as: as('/components/{category}/{slug}/{variantSlug}'),
    generic: true
  },
  quote: {
    pathname: '/quote',
    as: as('/quote')
  },
  customerTestingArea: {
    pathname: '/customer-testing-area',
    as: as('/customer-testing-area')
  },
  quoteDone: {
    pathname: '/quote-done',
    as: as('/quote/done'),
    excludeInSitemap: true
  },
  solutions: {
    pathname: '/solutions',
    as: as('/solutions')
  },
  matrix: {
    pathname: '/matrix',
    as: as('/matrix')
  },
  peripheralRuleset: {
    pathname: '/peripheralRuleset',
    as: as('/peripheralRuleset')
  },
  meetingSuccess: {
    pathname: '/landing-pages/calendly',
    as: as('/meeting')
  },
  solution: {
    pathname: '/solutions',
    as: as('/solutions/{slug}'),
    generic: true
  },
  rbtxpert: {
    pathname: '/rbtxpert',
    as: as('/rbtxpert')
  },
  community: {
    pathname: '/community',
    as: as('/community/{slug}')
  },
  rbtxMethod: {
    pathname: '/rbtx-method',
    as: as('/rbtx-method')
  },
  machinePlanner: {
    pathname: '/machine-planner',
    as: as('/machine-planner')
  },
  newsletter: {
    pathname: '/newsletter',
    as: as('/newsletter')
  },
  newsletterDone: {
    pathname: '/newsletter/done',
    as: as('/newsletter/done'),
    excludeInSitemap: true
  }
};

export default pathMap;
