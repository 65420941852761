import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { X } from 'react-feather';
import PathMap from '~/shared/pathMap';
import styles from './styles.module.scss';
import { useFeatureFlag } from '~/lib/hooks/abby';
import trackEvent from '~/lib/analytics.service';
import Image from 'next/image';
import { buildAssetUrl } from '~/lib/assetPathHelper';
import { useMachinePlannerUrl } from '~/lib/hooks/useMachinePlannerUrl';

type Props = {
  onClose: () => void;
  isOpen: boolean | undefined;
};

export const SideNav = ({ isOpen, onClose }: Props) => {
  const showCommunityDesignLink = useFeatureFlag('CommunityDesignLink');

  const { t } = useTranslation();
  const { pathname: pathName } = useRouter();

  return (
    <>
      <nav
        className={classNames('w-screen z-50 h-screen inset-0 bg-black/50', {
          fixed: isOpen,
          hidden: !isOpen
        })}
        onClick={(e) => {
          // only use clicks on the backdrop to close the sidenav
          if (e.target === e.currentTarget) {
            onClose();
          }
        }}
      />
      <ul
        className={classNames(
          styles.sidenav,
          'inset-0 w-6/12 bg-white text-base text-black fixed z-50',
          { hidden: !isOpen },
          {
            [classNames(styles['fadeIn'], styles['animated'])]: isOpen,
            [classNames(styles['fadeOut'], styles['animated'])]: isOpen === false
          }
        )}
      >
        <div className="flex justify-end px-6 my-6">
          <button className="bg-transparent border-none" onClick={onClose}>
            <X size={24} strokeWidth={2} />
          </button>
        </div>
        <li className={styles['nav-item']}>
          <Link
            href={PathMap.index.as()}
            className={classNames(
              styles['nav-link'],
              // use startsWith here because the path is dynamic (e.g. /components/robots)
              pathName.startsWith(PathMap.index.pathname) ? 'text-brand-darkPurple font-bold' : 'text-black',
              'font-bold text-base text-black'
            )}
          >
            {t('breadcrumbHomeLinkText')}
          </Link>
        </li>
        <li className={styles['nav-item']}>
          <Link
            href={PathMap.components.as(['robots'])}
            className={classNames(
              styles['nav-link'],
              // use startsWith here because the path is dynamic (e.g. /components/robots)
              pathName.startsWith(PathMap.industry.pathname)
                ? 'text-brand-darkPurple font-bold'
                : 'text-black',
              'font-bold text-base text-black'
            )}
          >
            {t('headerComponentsText')}
          </Link>
        </li>
        <li className={styles['nav-item']}>
          <Link
            href={PathMap.solutions.as()}
            className={classNames(
              styles['nav-link'],
              'text-base font-bold',
              pathName === PathMap.solutions.pathname || pathName === PathMap.solution.pathname
                ? 'text-brand-darkPurple font-bold'
                : 'text-black'
            )}
          >
            {t('headerUseCasesLinkText')}
          </Link>
        </li>
        {showCommunityDesignLink && (
          <li className={styles['nav-item']}>
            <Link
              href="https://tpdb2.truphysics.com/trubotics/RBTXperience-Desk/"
              onClick={() => {
                trackEvent('RBTXperience_topnavi', 'click');
              }}
              className={classNames(styles['nav-link'], 'text-base font-bold text-black')}
            >
              RBTXperience
            </Link>
          </li>
        )}
        <li className={styles['nav-item']}>
          <Link
            href={PathMap.community.as([''])}
            className={classNames(styles['nav-link'], 'text-base font-bold text-black')}
          >
            {t('headerToolText')}
          </Link>
          <ul className={(styles['nav-submenu'], 'px-0')}>
            {!!useMachinePlannerUrl() && (
              <li className={styles['nav-item']}>
                <Link
                  href={PathMap.machinePlanner.as([''])}
                  className={classNames(styles['nav-link'], ' flex text-base text-black')}
                >
                  {t('headerCommunityLinkText')}{' '}
                  {
                    <Image
                      src={buildAssetUrl(`/images/mp-planner/${t('common:mp-ads.new-icon')}.svg`)}
                      className="ml-1"
                      width="40"
                      height="20"
                      alt="New"
                    />
                  }
                </Link>
              </li>
            )}
            <li className={styles['nav-item']}>
              <Link
                href="https://gluing.rbtx.com/"
                prefetch={false}
                className={classNames(styles['nav-link'], 'text-base text-black')}
              >
                {t('headerGluingLinkText')}
              </Link>
            </li>
            <li className={styles['nav-item']}>
              <Link
                href="https://configurator.rbtx.com/7thaxis"
                prefetch={false}
                className={classNames(styles['nav-link'], 'text-base text-black')}
              >
                {t('header7thAxisLinkText')}
              </Link>
            </li>
            <li className={styles['nav-item']}>
              <Link
                href="https://tpdb2.truphysics.com/igus/Rebel/"
                prefetch={false}
                className={classNames(styles['nav-link'], 'text-base text-black')}
              >
                {t('headerRebelCobotLinkText')}
              </Link>
            </li>
            <li className={styles['nav-item']}>
              <Link
                href="https://tpdb2.truphysics.com/igus/gantry/"
                prefetch={false}
                className={classNames(styles['nav-link'], 'text-base text-black')}
              >
                {t('headerGantryLinkText')}
              </Link>
            </li>
            <li className={styles['nav-item']}>
              <Link
                href="https://igus.truphysics.com/Apiro/"
                prefetch={false}
                className={classNames(styles['nav-link'], 'text-base text-black')}
              >
                {t('headerApiroLinkText')}
              </Link>
            </li>
          </ul>
        </li>
        <li className={styles['nav-item']}>
          <Link
            href={PathMap.rbtxpert.as()}
            className={classNames(styles['nav-link'], 'text-base font-bold text-black')}
          >
            {t('headerServicesText')}
          </Link>
          <ul className={(styles['nav-submenu'], 'px-0')}>
            <li className={styles['nav-item']}>
              <Link
                href={PathMap.rbtxpert.as([''])}
                className={classNames(
                  styles['nav-link'],
                  'text-base',
                  pathName === PathMap.rbtxpert.pathname ? 'text-brand-darkPurple font-bold' : 'text-black'
                )}
              >
                {t('headerIntegratorLinkText')}
              </Link>
            </li>
            <li className={styles['nav-item']}>
              <Link
                href={PathMap.customerTestingArea.as([''])}
                className={classNames(
                  styles['nav-link'],
                  'text-base',
                  pathName === PathMap.customerTestingArea.pathname
                    ? 'text-brand-darkPurple font-bold'
                    : 'text-black'
                )}
              >
                {t('headerCTAText')}
              </Link>
            </li>
          </ul>
        </li>
        <li className={styles['nav-item']}>
          <Link
            href={PathMap.contact.as()}
            className={classNames(styles['nav-link'], 'text-base font-bold text-black')}
          >
            {t('headerHelpText')}
          </Link>
          <ul className={(styles['nav-submenu'], 'px-0')}>
            <li className={styles['nav-item']}>
              <Link
                href={PathMap.contact.as()}
                className={classNames(
                  styles['nav-link'],
                  'text-base',

                  pathName === PathMap.contact.pathname || pathName === PathMap.contactDone.pathname
                    ? 'text-brand-darkPurple font-bold'
                    : 'text-black'
                )}
              >
                {t('contactUsLinkText')}
              </Link>
            </li>
            <li className={styles['nav-item']}>
              <Link
                href={PathMap.help.as()}
                prefetch={false}
                className={classNames(
                  styles['nav-link'],
                  'text-base',
                  pathName === PathMap.help.pathname || pathName === PathMap.faqPost.pathname
                    ? 'text-brand-darkPurple font-bold'
                    : 'text-black'
                )}
              >
                {t('headerFAQandArticleLinkText')}
              </Link>
            </li>
            <li className={styles['nav-item']}>
              <Link
                href={PathMap.rbtxMethod.as()}
                prefetch={false}
                className={classNames(
                  styles['nav-link'],
                  'text-base',
                  pathName === PathMap.rbtxMethod.pathname ? 'text-brand-darkPurple font-bold' : 'text-black'
                )}
              >
                {t('headerRBTXMethodLinkText')}
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </>
  );
};
