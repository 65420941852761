import { Dialog, Transition } from '@headlessui/react';
import create from 'zustand';
import { Fragment } from 'react';
import Image from 'next/image';
import { X } from 'react-feather';

export const useChinaQRCodeModal = create<{
  isQRModalOpen: boolean;
  setIsQRModalOpen: (isQRModalOpen: boolean) => void;
}>((set) => ({
  isQRModalOpen: false,
  setIsQRModalOpen: (isQRModalOpen) => set({ isQRModalOpen })
}));

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const ChinaQRCodeModal = ({ isOpen, onClose }: Props) => {
  return (
    <Transition
      show={isOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
      as={Fragment}
    >
      <Dialog open={isOpen} onClose={onClose} className="absolute w-screen inset-0 z-[1200]">
        <div className="fixed inset-0 bg-black/70" aria-hidden="true" />

        <div className="fixed inset-0 p-3 overflow-y-auto">
          <div className="flex items-center justify-center xl:mt-20 md:min-h-0">
            <Dialog.Panel
              as="div"
              className="relative  max-w-2xl lg:max-w-6xl rounded-lg bg-white shadow-md md:min-h-0 overflow-y-scroll md:overflow-y-auto"
            >
              <button className="bg-transparent absolute right-4 top-4 border-0" onClick={onClose}>
                <X />
              </button>
              <div className="pt-6">
                <div className="text-xl md:text-2xl font-medium text-black mx-7 md:text-center -mt-2">
                  微信扫码开始咨询
                </div>
                <div className="flex items-center">
                  <Image
                    src="/images/calendly/wechatqr.png"
                    alt="https://work.weixin.qq.com/ct/wcde400df4373fe52f9b8acd6c66787b9e7e"
                    width={300}
                    height={300}
                  />
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
