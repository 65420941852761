/* eslint-disable @next/next/no-img-element */

import React from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import { buildAssetUrl } from '~/lib/assetPathHelper';
import PathMap from '~/shared/pathMap';
// import styles from './styles.module.scss';

type Props = {
  theme?: 'white' | string;
  className?: string;
};

const Brand = ({ theme, className }: Props) => {
  const imageClass = classNames('h-[40px] md:h-[50px]');

  const assetLight = buildAssetUrl('/images/logo-white.svg');
  const assetDark = buildAssetUrl('/images/logo.svg');

  return (
    <div className={classNames(theme, className)}>
      <Link href={PathMap.index.pathname}>
        <img
          className={classNames(imageClass, {
            hidden: theme !== 'white'
          })}
          src={assetLight}
          alt="logo"
        />
        <img
          className={classNames(imageClass, {
            hidden: theme === 'white'
          })}
          src={assetDark}
          alt="logo"
        />
      </Link>
    </div>
  );
};

export default Brand;
