import { useRouter } from 'next/router';
import { useCountryCode } from './useCountryCode';

const mpLinkDE = 'https://configurator.rbtx.com/de/de/machine-planner/designer';
const mpLinkEN = 'https://configurator.rbtx.com/de/en/machine-planner/designer';
const mpLinkUS = 'https://configurator.rbtx.com/us/en/machine-planner/designer';
const mpLinkATde = 'https://configurator.rbtx.com/at/de/machine-planner/designer';
const mpLinkATen = 'https://configurator.rbtx.com/at/en/machine-planner/designer';

/**
 * Returns the appropriate Machine Planner URL based on the country code & locale.
 *
 * @returns {string|null} The URL for the Machine Planner in the correct language, or null if the country is not supported.
 */
export const useMachinePlannerUrl = () => {
  const country = useCountryCode();
  const router = useRouter();

  switch (country) {
    case 'DE':
      return router.locale === 'de-DE' ? mpLinkDE : mpLinkEN;
    case 'US':
      return mpLinkUS;
    case 'AT':
      return router.locale === 'de-AT' ? mpLinkATde : mpLinkATen;
    default:
      return null;
  }
};
