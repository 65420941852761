// eslint-disable-next-line @typescript-eslint/no-var-requires
const defaultTheme = require('tailwindcss/defaultTheme');

/** @type {import('tailwindcss').Config} */
module.exports = {
  important: true,
  content: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
    './layouts/**/*.{js,ts,jsx,tsx}'
  ],
  theme: {
    extend: {
      borderRadius: {
        lg: '10px'
      },
      fontSize: {
        heading: '56px'
      },
      fontFamily: {
        sans: ['var(--font-roboto)', ...defaultTheme.fontFamily.sans],
        inter: ['var(--font-inter)', ...defaultTheme.fontFamily.sans]
      },
      container: {
        padding: {
          DEFAULT: '1.5rem',
          md: '3rem'
        },
        center: true
      },
      colors: {
        brand: {
          purple: '#7447F2',
          darkPurple: '#4D3880',
          // darkPurple: '#2F1C60', // legacy
          orange: '#FF6D00',
          black: '#232323',
          lightGray: '#F7F8FC',
          darkBlue: '#2D3748'
        }
      },
      animation: {
        marquee: 'marquee 80s linear infinite',
        marquee2: 'marquee2 80s linear infinite'
      },
      keyframes: {
        marquee: {
          '0%': { transform: 'translateX(0%)' },
          '100%': { transform: 'translateX(-100%)' }
        },
        marquee2: {
          '0%': { transform: 'translateX(100%)' },
          '100%': { transform: 'translateX(0%)' }
        }
      },
      backgroundImage: {
        'gradient-radial': 'radial-gradient(var(--tw-gradient-stops))'
      }
    }
  },
  plugins: [require('@tailwindcss/typography'), require('@tailwindcss/line-clamp')]
};
