import { useCountryCode } from '~/lib/hooks/useCountryCode';

const calendlyLinkDE = 'https://calendly.com/rbtxpert/beratungsgespraech';
const calendlyLinkEN = 'https://calendly.com/rbtxpert/meeting';
const calendlyLinkUK = 'https://calendly.com/rbtxpert-uk/meeting';
const calendlyLinkFR = 'https://calendly.com/rbtxpert/consultation';
const calendlyLinkIT = 'https://calendly.com/rbtxpert/consulenza-personale-rbtxpert';
const calendlyLinkUS = 'https://calendly.com/rbtxpert-usa/meeting';
const calendlyLinkCA = 'https://calendly.com/rbtxpert-usa/meeting';
const calendlyLinkMX = 'https://calendly.com/rbtxpert-usa/meeting';

const countryOverrides: Record<string, string> = {
  US: calendlyLinkUS,
  GB: calendlyLinkUK,
  MX: calendlyLinkMX,
  CA: calendlyLinkCA
};

// TODO: Maybe handle it more obviously that initially this will just return canlenyLinkEN due to the country not being available instantly (has always been this case)
export const useCreateCalendlyLink = (locale?: string) => {
  const country = useCountryCode();

  switch (country) {
    case 'DE': {
      return calendlyLinkDE;
    }
    case 'AT': {
      return calendlyLinkDE;
    }
    case 'FR': {
      return calendlyLinkFR;
    }
    case 'CH': {
      return locale == 'fr-CH' ? calendlyLinkFR : locale == 'it-CH' ? calendlyLinkIT : calendlyLinkDE;
    }
    case 'IT': {
      return calendlyLinkIT;
    }
    default: {
      if (country && countryOverrides[country]) {
        return countryOverrides[country];
      }

      return calendlyLinkEN;
    }
  }
};
