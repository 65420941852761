import { Dialog, Transition } from '@headlessui/react';
import { Clock, Cpu, Package, Smile, X as CloseIcon } from 'react-feather';
import { Button } from '../button';
import { useCreateCalendlyLink } from '~/shared/calendly';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import pathMap from '~/shared/pathMap';
import { useCalendlyModal } from '~/lib/hooks/useCalendlyModal';
import trackEvent from '~/lib/analytics.service';

type ModalIconProps = {
  number: number;
  description: string;
  iconName: string;
};

function ModalIcon({ description, iconName, number }: ModalIconProps) {
  return (
    <div className="flex flex-col items-center">
      <div className="h-24 lg:h-36 w-48 lg:w-64">
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img className="w-full h-full" src={`/images/calendly/${iconName}.svg`} alt={iconName} />
      </div>
      <div className="flex mt-3 lg:mt-6 items-center justify-between">
        <span className="h-6 w-6 bg-brand-orange rounded-full text-white flex justify-center items-center mr-1.5 text-sm p-2">
          {number}
        </span>
        <p className="text-black text-xs lg:text-base m-0 lg:w-[240px]">{description}</p>
      </div>
    </div>
  );
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export function CalendlyModal({ isOpen, onClose }: Props) {
  const setIsOpen = useCalendlyModal((state) => state.setIsOpen);
  const router = useRouter();
  const { t } = useTranslation();

  const trackCalendlyClick = () => {
    trackEvent('CalendlyModal', 'click');
  };

  const ICONS = [
    {
      icon: 'appointment',
      description: t('calendlyModal.step1')
    },
    {
      icon: 'conception',
      description: t('calendlyModal.step2')
    },
    {
      icon: 'components',
      description: t('calendlyModal.step3')
    },
    { icon: 'offer', description: t('calendlyModal.step4') }
  ];

  const STEPS = [
    { title: t('calendlyModal.info1'), icon: Cpu },
    { title: t('calendlyModal.info2'), icon: Clock },
    { title: t('calendlyModal.info3'), icon: Package },
    { title: t('calendlyModal.info4'), icon: Smile }
  ];

  return (
    <Transition
      show={isOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
      as={Fragment}
    >
      <Dialog open={isOpen} onClose={onClose} className="fixed h-screen w-screen inset-0 z-[1200]">
        {/* Backdrop */}
        <div className="fixed inset-0 bg-black/70" aria-hidden="true" />

        {/* Full-screen scrollable container */}
        <div className="fixed inset-0 p-3 overflow-y-auto">
          {/* The actual dialog panel  */}
          <div className="flex min-h-full items-center justify-center">
            <Dialog.Panel as="div" className="w-full max-w-md lg:max-w-5xl rounded-lg bg-white p-4 shadow-md">
              <div className="flex justify-end mb-4">
                <button onClick={onClose} className="border-0 bg-transparent">
                  <CloseIcon />
                </button>
              </div>
              <div className="flex flex-col lg:flex-row">
                <div className="grid grid-cols-2 gap-y-6 gap-x-2 flex-1">
                  {ICONS.map((icon, i) => (
                    <ModalIcon
                      key={icon.icon}
                      iconName={icon.icon}
                      description={icon.description}
                      number={i + 1}
                    />
                  ))}
                </div>
                <div className="px-6 lg:max-w-xs">
                  <h2 className="mt-6 lg:mt-0 mb-3 text-lg font-semibold text-left">
                    {t('calendlyModal.title')}
                  </h2>
                  <p className="my-3 text-sm">{t('calendlyModal.subtitle')}</p>
                  <ul className="list-none p-0">
                    {STEPS.map(({ icon: Icon, title }) => (
                      <li key={title}>
                        <span className="mr-2">{<Icon size={20} />}</span>
                        <span className="text-sm">{title}</span>
                      </li>
                    ))}
                  </ul>
                  <p className="text-center font-semibold text-sm leading-6 my-4">
                    {t('calendlyModal.freeCall')}
                    <br />
                    {t('calendlyModal.nonBindingQuote')}
                  </p>
                  <div className="flex items-center flex-col space-y-3 text-base font-medium">
                    <a
                      id="calendly-modal-link"
                      href={useCreateCalendlyLink(router.locale)}
                      onMouseDown={() => trackCalendlyClick()}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button className="px-12">{t('calendlyModal.cta')}</Button>
                    </a>

                    <Link id={'calendly-modal-learn-more'} href={pathMap.rbtxpert.as()} className="w-full">
                      <Button className="px-12 w-full" variant="inverted" onClick={() => setIsOpen(false)}>
                        {t('calendlyModal.learnMore')}
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
